const getDefaultState = () => {
  return {
    lastRefreshPayload: null,
  };
};

const state = getDefaultState();

const getters = {
  getLastRefreshPayload: (state) => state.lastRefreshPayload,
};

const mutations = {
  setLastRefreshPayload(state, payload) {
    state.lastRefreshPayload = payload;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
