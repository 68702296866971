<template>
  <v-app data-testid="app" :style="appStyles">
    <v-main>
      <AppBar v-if="$route.path !== '/login'" style="z-index: 7" />

      <transition name="fadeRouter" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <SessionExpiredDialog />
    <ReloadDialog />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AppBar from "@/components/AppBar";
import SessionExpiredDialog from "@/components/znap/SessionExpiredDialog";
import ReloadDialog from "@/components/znap/ReloadDialog";

export default {
  name: "App",

  components: {
    AppBar,
    SessionExpiredDialog,
    ReloadDialog,
  },

  computed: {
    ...mapGetters("theme", ["getTheme"]),

    appStyles() {
      return {
        background: "#ddd",
        "background-image": 'url("/img/background.jpg")',
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "-webkit-background-size": "cover",
        "-moz-background-size": "cover",
        "-o-background-size": "cover",
        "overflow-y": "hidden",
        "scroll-behavior": "smooth",
        "line-height": this.$route.path.includes("editor") ? "1.2" : "1.5",
      };
    },
  },

  async created() {
    this.setTheme();
  },

  methods: {
    setTheme() {
      const colours = require(`@/plugins/themes/${this.getTheme}`);
      this.$vuetify.theme.themes.light = colours.default;
    },
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>

<style scoped>
/* #app {
  background: #ddd;
  background-image: url("../public/img/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  overflow-y: hidden;
  scroll-behavior: smooth;
} */

body {
  overflow-y: hidden !important;
}

.fadeRouter-enter {
  opacity: 0;
}

.fadeRouter-enter-active {
  transition: opacity 0.28s ease;
}

.fadeRouter-leave-active {
  transition: opacity 0.28s ease;
  opacity: 0;
}

.v-img {
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
</style>
