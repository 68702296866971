import Vue from "vue";
import Vuetify from "vuetify/lib";

import pt from "./locales/pt";
import en from "./locales/en";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "sm", // This is equivalent to a value of 960
  },
  theme: {
    themes: {
      light: {},
      dark: false,
    },
  },
  lang: {
    locales: { pt, en },
    current: "pt",
  },
});
