export default function setModule(request, defaultModule) {
  let endpoint = request.url;
  let module = "";

  switch (true) {
    case endpoint.indexOf("user/menu") !== -1:
      module = request.data.module;
      break;
    case endpoint.indexOf("user/hash/login") !== -1:
      module = "TORII_SECURITY";
      break;
    case endpoint.indexOf("user/transactions") !== -1:
      module = "TORII_SECURITY";
      break;
    case endpoint.indexOf("extra-event-customer") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("extract-planning-product") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("status") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("email-approval-level") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("file-type") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("sales-force") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("distributor") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("approval-group-area-name") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("group-distributor") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("stock-group") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("packaging-type") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("product") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("group-transaction") !== -1:
      module = "TORII_SECURITY";
      break;
    case endpoint.indexOf("subgroup") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("group") !== -1:
      module = "TORII_SECURITY";
      break;
    case endpoint.indexOf("customer-type") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("customer") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("user-substitute") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("product-disactivated") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("period-control") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("new-action-plan") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("action-plan") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("action-plan-approval-flow") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("extra-event") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("extract-payment") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("payment-type") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("price-type") !== -1:
      module = "TORII_REFUND";
      break;
    case endpoint.indexOf("swap") !== -1:
      module = "TORII_SWAP";
      break;
    case endpoint.indexOf("action") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("contact") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("fund-combination") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("fund-combination-fund-type") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("fund-type") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("unit-measurement") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("erp-integration") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("erp-integration-run") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("to-erp-integration") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("to-erp-integration-run") !== -1:
      module = "TORII_MASTER_DATA";
      break;
    case endpoint.indexOf("approval-flow") !== -1:
      module = "TORII_APPROVAL_FLOW";
      break;
    default:
      module = defaultModule;
  }

  return module;
}
