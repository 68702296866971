import axios from "axios";
import Vue from "vue";
import store from "@/store/index.js";
import setModule from "@/helpers/setModule.js";

const instance = createInstance();

function createInstance() {
  return axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
}

instance.interceptors.request.use(
  (request) => {
    if (store.state.auth.token) {
      request.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
    }

    let dataDefault = {
      language:
        store.state.system.language === "pt_BR"
          ? "pt"
          : store.state.system.language,
      clientId: store.state.system.clientId,
      system: store.state.system.system,
    };

    let module = setModule(request, store.state.system.module);

    if (request.data instanceof FormData) {
      request.headers.common["Content-Type"] = "multipart/form-data";

      request.data.append("language", dataDefault.language);
      request.data.append("clientId", dataDefault.clientId);
      request.data.append("module", module);
      request.data.append("system", dataDefault.system);
    } else {
      request.data = {
        ...dataDefault,
        ...request.data,
        module,
      };
    }

    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

Vue.prototype.$http = instance;

export default {
  getInstance: () => instance,
};
