<template>
  <div>
    <v-dialog v-model="getSessionExpiredDialog" max-width="900px" persistent>
      <v-dialog v-model="passwordDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title class="headline pr-4">
            Renovar sessão

            <v-spacer></v-spacer>

            <v-btn class="pa-0" @click="passwordDialog = false" text icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="py-4">
            <v-text-field
              color="primary"
              label="Senha"
              prepend-icon="mdi-lock"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="showPassword = !showPassword"
              v-model="password"
            ></v-text-field>
          </v-card-text>

          <v-card-actions class="px-6 py-6">
            <v-spacer></v-spacer>

            <v-btn
              @click="passwordDialog = false"
              color="primary"
              class="mr-3"
              text
            >
              Cancelar
            </v-btn>

            <v-btn
              color="primary"
              @click="login()"
              :loading="loading"
              :disabled="!validPassword"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card style="position: relative">
        <v-card-title
          class="light--text"
          :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
          style="position: sticky; top: 0; width: 100%; z-index: 1000"
        >
          <span class="white--text headline">Sessão expirada</span>
        </v-card-title>
        <v-card-text class="py-4">
          <p class="ma-0">
            Escolha <strong>RENOVAR</strong> para continuar de onde parou ou
            <strong>FINALIZAR</strong> para encerrar a sessão atual e retonar
            para a tela de login.
          </p>
        </v-card-text>
        <div class="light" style="position: sticky; bottom: 0; width: 100%">
          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="px-6 py-6">
            <v-spacer></v-spacer>
            <v-btn color="primary" text class="mr-4" @click="endSession()">
              Finalizar
            </v-btn>
            <v-btn color="primary" @click="passwordDialog = true">
              Renovar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import configs from "@/configs";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      passwordDialog: false,
      showPassword: false,
      password: "",
      loading: false,
    };
  },

  computed: {
    ...mapGetters("auth", [
      "getUser",
      "getHash",
      "getSessionExpiredDialog",
      "getUrlPathName",
    ]),

    validPassword() {
      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );

      if (strongRegex.test(this.password)) {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted() {
    window.addEventListener("keydown", this.keyDownHandler);
  },

  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },

  methods: {
    ...mapMutations("auth", ["setSessionExpiredDialog"]),
    ...mapActions("auth", ["authenticate", "logout"]),

    keyDownHandler(event) {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        this.password.length >= 6
      ) {
        this.login();
      }
    },

    async login() {
      this.loading = true;

      try {
        const res = await this.authenticate({
          email: this.getUser.email,
          password: this.password,
        });
        if (res) {
          this.$toast.success("Sessão renovada com sucesso");
          this.setSessionExpiredDialog(false);
          this.loading = false;
          location.reload();
        } else {
          this.loading = false;
          this.$fnError(res);
        }
      } catch (err) {
        this.$fnError(err);
        this.loading = false;
      }
    },

    redirectToPathName(pathName) {
      let url = null;

      let env = "";

      if (configs.env === "homol") {
        env = "-homol";
      } else if (configs.env === "dev") {
        env = ".dev";
      }

      if (window.location.href.includes(":80")) {
        url = "http://localhost:8080";
      } else if (configs.env === "prod" || configs.env === "homol") {
        url = `https://${configs.url}${env}.nissin.com.br`;
      } else {
        url = `https://${configs.url}${env}.znaptech.com`;
      }

      this.setUrlPathName(null);
      return (window.location.href = `${url}${pathName}`);
    },

    endSession() {
      return this.logout();
    },
  },
};
</script>

<style></style>
