const configs = {
  clientId: 100,
  url: "torii",
  system: "NISSIN-TORII",
  language: "pt",
  theme: "nissin.js",
  env: "dev",
  portal_url: "https://portal-cliente-homol.nissin.com.br"
};

export default configs;