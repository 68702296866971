export default function setApiLocalPort(path) {
  if (path === "user") {
    return 9000;
  } else if (path === "client") {
    return 9001;
  } else if (path === "security") {
    return 9002;
  } else if (path === "unit") {
    return 9008;
  } else if (path === "sales.area") {
    return 9012;
  } else if (path === "event") {
    return 9013;
  } else if (path === "masterdata") {
    return 9014;
  } else if (path === "approval.flow") {
    return 9015;
  } else if (path === "refund") {
    return 9016;
  } else if (path === "swap") {
    return 9017;
  } else if (path === "integration") {
    return 9023;
  }
}
