import setApiLocalPort from "./setApiLocalPort";

export default function setApiRoutes(Vue, url, env) {
  let domain = ''

  if (env === "prod" || env === "homol") {
    domain = ".nissin.com.br/";
  } else {
    domain = ".znaptech.com/";
  }


  const setProtocol = (environment) => {
    return environment === "local"
      ? "http://"
      : "https://";
  };

  const setApi = (environment) => {
    let apiName = "";

    if (environment === "prod") {
      return `api-${url.toLowerCase()}${domain}`;
    }

    if (environment === "homol") {
      return `api-${url.toLowerCase()}-homol${domain}`;
    }

    if (environment === "dev") {
      apiName = "dev.";
    }

    return `${url.toLowerCase()}.api.${apiName}`;
  };

  // eslint-disable-next-line
  const setRoute = (path) => {
    const protocol = setProtocol(env);

    if (env === "local") {
      const apiLocalPort = setApiLocalPort(path);
      return `${protocol}localhost:${apiLocalPort}/`;
    } else if (env === "prod" || env === "homol") {
      const api = setApi(env);
      return `${protocol}${api}${path.split('.').join('')}Service/`;
    } else {
      const api = setApi(env);
      return `${protocol}${api}${path}${domain}`;
    }
  };

  Vue.prototype.$ipUser = setRoute("user");
  Vue.prototype.$ipClient = setRoute("client");
  Vue.prototype.$ipSecurity = setRoute("security");
  Vue.prototype.$ipUnit = setRoute("unit");
  Vue.prototype.$ipSalesArea = setRoute("sales.area");
  Vue.prototype.$ipEvent = setRoute("event");
  Vue.prototype.$ipMasterData = setRoute("masterdata");
  Vue.prototype.$ipApprovalFlow = setRoute("approval.flow");
  Vue.prototype.$ipRefund = setRoute("refund");
  Vue.prototype.$ipSwap = setRoute("swap");
  Vue.prototype.$ipIntegration = setRoute("integration");
}
